var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Orders ("),_c('span',{domProps:{"textContent":_vm._s(_vm.totalItems)}}),_vm._v(") "),_c('v-spacer'),(this.$route.name !== 'hireOrders' && !_vm.isReadOnly)?_c('v-btn',{staticStyle:{"margin-left":"10px","margin-right":"10px"},attrs:{"disabled":_vm.internalOrderCreationInProgress,"color":"primary"},on:{"click":function($event){return _vm.openAddSaleDialog()}}},[_vm._v(" Add ")]):_vm._e(),(!_vm.isReadOnly)?_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.exportOrders()}}},[_vm._v(" Export ")]):_vm._e(),(this.$route.name === 'saleOrders')?_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"color":"primary"},on:{"click":function($event){_vm.wipDialog = true}}},[_vm._v(" WIP Report ")]):_vm._e(),(
          this.$route.name === 'saleOrders' ||
            this.$route.name == 'hireOrders'
        )?_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.salesTargetDispatchReport()}}},[_vm._v(" Target Dispatch Report ")]):_vm._e()],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.wipDialog),callback:function ($$v) {_vm.wipDialog=$$v},expression:"wipDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"py-3"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"To","readonly":"","clearable":""},model:{value:(_vm.computedToDateFormat),callback:function ($$v) {_vm.computedToDateFormat=$$v},expression:"computedToDateFormat"}},on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.toDateMenu = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","small":""},on:{"click":function($event){$event.stopPropagation();_vm.wipDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.confirmPrint()}}},[_vm._v("Confirm")])],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading || _vm.localLoading,"items":_vm.orders,"headers":_vm.headers,"search":_vm.search,"server-items-length":_vm.orders.length,"items-per-page":-1,"height":_vm.tableHeight,"fixed-header":"","footer-props":{
        'items-per-page-options': [-1],
        'items-per-page-text': ''
      },"options":_vm.options},on:{"click:row":_vm.rowClick,"update:options":[function($event){_vm.options=$event},_vm.handleUpdateOptions]},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [(item.displayMarkCompleteButton)?_c('v-btn',{attrs:{"elevation":"0","color":"transparent","small":"","title":"This action will remove all remaining stock allocations that were not fully invoiced from this Order"},on:{"click":function($event){$event.stopPropagation();return _vm.markAsComplete(item)}}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-checkbox-marked-circle")])],1):_c('span')]}}],null,true)})]}},{key:"item.created",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toLocalTime")(item.created)))])]}},{key:"item.firstItemDescription",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"descText",domProps:{"innerHTML":_vm._s(item.firstItemDescription)}})]}},{key:"item.internalOrderGridStatus",fn:function(ref){
      var item = ref.item;
return [(item.internalOrderGridStatus === 'Complete' && item.internal)?_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v(_vm._s(item.internalOrderGridStatus))]):_vm._e(),(
            item.internalOrderGridStatus === 'Outstanding' && item.internal
          )?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(_vm._s(item.internalOrderGridStatus))]):_vm._e(),(item.internalOrderGridStatus === 'Cancelled' && item.internal)?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(_vm._s(item.internalOrderGridStatus))]):_vm._e()]}},{key:"item.gridStatus",fn:function(ref){
          var item = ref.item;
return [(item.gridStatus === 'Complete' && !item.internal)?_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v(_vm._s(item.gridStatus))]):_vm._e(),(item.gridStatus === 'Part Invoiced' && !item.internal)?_c('v-chip',{attrs:{"color":"pink","dark":""}},[_vm._v(_vm._s(item.gridStatus))]):_vm._e(),(item.gridStatus === 'Outstanding' && !item.internal)?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(_vm._s(item.gridStatus))]):_vm._e(),(item.gridStatus === 'Cancelled' && !item.internal)?_c('v-chip',{attrs:{"color":"orange","dark":""}},[_vm._v(_vm._s(item.gridStatus))]):_vm._e(),(item.gridStatus === 'No Items' && !item.internal)?_c('span',[_vm._v(_vm._s(item.gridStatus))]):_vm._e()]}},{key:"item.hireOrderGridStatus",fn:function(ref){
          var item = ref.item;
return [(item.hireOrderGridStatus === 'On Hire')?_c('v-chip',{attrs:{"color":"purple","dark":""}},[_vm._v(_vm._s(item.hireOrderGridStatus))]):_vm._e(),(item.hireOrderGridStatus === 'Off Hire')?_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v(_vm._s(item.hireOrderGridStatus))]):_vm._e(),(item.hireOrderGridStatus === 'No Assemblies')?_c('span',[_vm._v(_vm._s(item.hireOrderGridStatus))]):_vm._e()]}}])}),_c('div',{staticClass:"text-center mt-2"},[(_vm.orders.length < _vm.totalItems)?_c('v-btn',{staticClass:"primary",attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.loadMore}},[_vm._v(" Load More ")]):_vm._e()],1)],1),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.color},on:{"close":function($event){_vm.snackbar = false}}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"850px"},model:{value:(_vm.addSaleDialog),callback:function ($$v) {_vm.addSaleDialog=$$v},expression:"addSaleDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Create Sale Order ")]),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"saleOrderForm",on:{"submit":function($event){$event.preventDefault();return _vm.createSaleOrder($event)}}},[(!_vm.type === 'Internal')?_c('v-row',[_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-checkbox',{attrs:{"label":"Internal Order"},model:{value:(_vm.isInternal),callback:function ($$v) {_vm.isInternal=$$v},expression:"isInternal"}})],1)],1):_vm._e(),(
              !_vm.isInternal &&
                _vm.selectedCustomerStatus &&
                _vm.selectedCustomerStatus !== 'Open'
            )?_c('v-row',{attrs:{"hide-details":""}},[_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6","hide-details":""}},[_c('strong',{staticStyle:{"color":"orange"}},[_vm._v("! Customer Status is "+_vm._s(_vm.selectedCustomerStatus)+" !")])])],1):_vm._e(),(!_vm.isInternal)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","hide-details":""}},[_c('v-autocomplete',{attrs:{"outlined":"","label":"Customer*","items":_vm.customers,"item-text":"name","item-value":"customerId","placeholder":"Select Customer","rules":[_vm.required]},on:{"change":_vm.customerChanged},model:{value:(_vm.saleOrder.customerId),callback:function ($$v) {_vm.$set(_vm.saleOrder, "customerId", $$v)},expression:"saleOrder.customerId"}})],1)],1):_vm._e(),(!_vm.isInternal)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","hide-details":""}},[_c('v-autocomplete',{attrs:{"outlined":"","label":"Invoice Address*","item-text":"nameInfoFullAddress","item-value":"customerAddressId","items":_vm.addresses,"placeholder":"Select Invoice Address","rules":[_vm.required]},on:{"change":_vm.customerInvoiceAddressSelected},model:{value:(_vm.saleOrder.customerInvoiceAddressId),callback:function ($$v) {_vm.$set(_vm.saleOrder, "customerInvoiceAddressId", $$v)},expression:"saleOrder.customerInvoiceAddressId"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',{staticClass:"grey lighten-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){;(_vm.addSaleDialog = false),
              (_vm.isInternal = false),
              (_vm.saleOrder.customerInvoiceAddressId = ''),
              (_vm.saleOrder.customerId = '')}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":_vm.saleOrderCreationInProgress,"color":"primary"},on:{"click":function($event){return _vm.createSaleOrder()}}},[_vm._v("Create")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.successMesssage)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }