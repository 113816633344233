var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Purchase Orders ("),_c('span',{domProps:{"textContent":_vm._s(_vm.totalItems)}}),_vm._v(") "),_c('v-spacer'),(_vm.isAdmin || _vm.isAdminDepartment || _vm.isInventory || _vm.isAccounts)?_c('v-btn',{attrs:{"to":"/purchaseOrder-new","color":"primary"}},[_vm._v(" New ")]):_vm._e(),_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"color":"primary","outlined":""},on:{"click":_vm.exportPurchaseOrders}},[_vm._v(" Export ")]),_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.poTargetDispatchReport()}}},[_vm._v(" Target Received Report ")])],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.loading || _vm.localLoading,"items":_vm.purchaseOrders,"headers":_vm.headers,"search":_vm.search,"server-items-length":_vm.purchaseOrders.length,"items-per-page":-1,"height":_vm.tableHeight,"fixed-header":"","footer-props":{
        'items-per-page-options': [-1],
        'items-per-page-text': ''
      },"options":_vm.options},on:{"click:row":_vm.rowClick,"update:options":[function($event){_vm.options=$event},_vm.handleUpdateOptions]},scopedSlots:_vm._u([{key:"item.firstItemDescription",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"descText",domProps:{"innerHTML":_vm._s(item.firstItemDescription)}})]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.status),"dark":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.requiredDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.requiredDate).toLocaleDateString('en-GB')))])]}}])}),_c('div',{staticClass:"text-center mt-2"},[(_vm.purchaseOrders.length < _vm.totalItems)?_c('v-btn',{staticClass:"primary",attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.loadMore}},[_vm._v(" Load More ")]):_vm._e()],1)],1),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.snackbarColor},on:{"close":function($event){_vm.snackbar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }