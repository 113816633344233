var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('multi-filter',{attrs:{"title":"Orders","filters":_vm.activeFilters},on:{"clear-filters":_vm.clearFilters,"apply-filters":_vm.applyFilters,"remove-filter":_vm.removeFilter}},[_c('v-text-field',{attrs:{"label":"Number"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('number', 'Number', _vm.number.value)}},model:{value:(_vm.number.value),callback:function ($$v) {_vm.$set(_vm.number, "value", $$v)},expression:"number.value"}}),_c('v-text-field',{attrs:{"label":"Quote Number"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'quoteNumber',
          'Quote',
          _vm.quoteNumber.value
        )}},model:{value:(_vm.quoteNumber.value),callback:function ($$v) {_vm.$set(_vm.quoteNumber, "value", $$v)},expression:"quoteNumber.value"}}),_c('v-text-field',{attrs:{"label":"Company"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'customerName',
          'Company',
          _vm.customerName.value
        )}},model:{value:(_vm.customerName.value),callback:function ($$v) {_vm.$set(_vm.customerName, "value", $$v)},expression:"customerName.value"}}),_c('v-text-field',{attrs:{"label":"Company Country"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'customerCountry',
          'Company Country',
          _vm.customerCountry.value
        )}},model:{value:(_vm.customerCountry.value),callback:function ($$v) {_vm.$set(_vm.customerCountry, "value", $$v)},expression:"customerCountry.value"}}),_c('v-text-field',{attrs:{"label":"Customer PO"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'customerRef',
          'Customer PO',
          _vm.customerPO.value
        )}},model:{value:(_vm.customerPO.value),callback:function ($$v) {_vm.$set(_vm.customerPO, "value", $$v)},expression:"customerPO.value"}}),_c('v-text-field',{attrs:{"label":"PO Number"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'poNumber',
          'PO Number',
          _vm.poNumber.value
        )}},model:{value:(_vm.poNumber.value),callback:function ($$v) {_vm.$set(_vm.poNumber, "value", $$v)},expression:"poNumber.value"}}),_c('v-text-field',{attrs:{"label":"Description"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'allItemsDescription',
          'Description',
          _vm.description.value
        )}},model:{value:(_vm.description.value),callback:function ($$v) {_vm.$set(_vm.description, "value", $$v)},expression:"description.value"}}),_c('v-autocomplete',{attrs:{"items":_vm.users,"item-text":"name","item-value":"name","label":"Raised By","autocomplete":"off"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'createdBy',
          'Created By',
          _vm.createdBy.value
        )}},model:{value:(_vm.createdBy.value),callback:function ($$v) {_vm.$set(_vm.createdBy, "value", $$v)},expression:"createdBy.value"}}),_c('v-text-field',{attrs:{"label":"Account Code"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'accountCode',
          'Account Code',
          _vm.accountCode.value
        )}},model:{value:(_vm.accountCode.value),callback:function ($$v) {_vm.$set(_vm.accountCode, "value", $$v)},expression:"accountCode.value"}}),_c('v-text-field',{attrs:{"label":"Value"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'totalValueString',
          'Value',
          _vm.orderValue.value
        )}},model:{value:(_vm.orderValue.value),callback:function ($$v) {_vm.$set(_vm.orderValue, "value", $$v)},expression:"orderValue.value"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.createdDate.dateRange),"clearable":"","label":"Date","readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.createdDate.dateRange = []}}},on))]}}]),model:{value:(_vm.dateRangeMenu),callback:function ($$v) {_vm.dateRangeMenu=$$v},expression:"dateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'created',
            'Date',
            _vm.createdDate.dateRange
          )},"input":_vm.closeDatePicker},model:{value:(_vm.createdDate.dateRange),callback:function ($$v) {_vm.$set(_vm.createdDate, "dateRange", $$v)},expression:"createdDate.dateRange"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.invoiceDate.dateRange),"clearable":"","label":_vm.type === 'Sale' || _vm.type === 'Hire'
              ? 'Invoice Date'
              : 'Completed Date',"readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.invoiceDate.dateRange = []}}},on))]}}]),model:{value:(_vm.invoiceDateRangeMenu),callback:function ($$v) {_vm.invoiceDateRangeMenu=$$v},expression:"invoiceDateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'invoiceDate',
            'Invoice Date',
            _vm.invoiceDate.dateRange
          )},"input":_vm.closeDatePicker},model:{value:(_vm.invoiceDate.dateRange),callback:function ($$v) {_vm.$set(_vm.invoiceDate, "dateRange", $$v)},expression:"invoiceDate.dateRange"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.targetDispatchDate.dateRange),"clearable":"","label":"Target Dispatch Date","readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.targetDispatchDate.dateRange = []}}},on))]}}]),model:{value:(_vm.targetDispatchDateRangeMenu),callback:function ($$v) {_vm.targetDispatchDateRangeMenu=$$v},expression:"targetDispatchDateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'targetDispatch',
            'Target Dispatch Date',
            _vm.targetDispatchDate.dateRange
          )},"input":_vm.closeDatePicker},model:{value:(_vm.targetDispatchDate.dateRange),callback:function ($$v) {_vm.$set(_vm.targetDispatchDate, "dateRange", $$v)},expression:"targetDispatchDate.dateRange"}})],1),_c('v-select',{attrs:{"items":['Yes', 'No'],"label":"Invoiced"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'isInvoiced',
          'Invoiced',
          _vm.isInvoiced.value
        )}},model:{value:(_vm.isInvoiced.value),callback:function ($$v) {_vm.$set(_vm.isInvoiced, "value", $$v)},expression:"isInvoiced.value"}}),(_vm.type === 'Hire')?_c('v-select',{attrs:{"items":['Yes', 'No'],"label":"Hire Equipment Serial Numbers On Hire"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'hasOnHire',
          'Hire Equipment Serial Numbers On Hire',
          _vm.hasOnHire.value
        )}},model:{value:(_vm.hasOnHire.value),callback:function ($$v) {_vm.$set(_vm.hasOnHire, "value", $$v)},expression:"hasOnHire.value"}}):_vm._e(),(_vm.type == 'Sale')?_c('v-select',{attrs:{"items":[
        'Outstanding',
        'Part Invoiced',
        'Complete',
        'No Items',
        'Cancelled'
      ],"label":"Status"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'gridStatus',
          'Status',
          _vm.saleStatus.value
        )}},model:{value:(_vm.saleStatus.value),callback:function ($$v) {_vm.$set(_vm.saleStatus, "value", $$v)},expression:"saleStatus.value"}}):_vm._e(),(_vm.type == 'Hire')?_c('v-select',{attrs:{"items":['On Hire', 'Off Hire'],"label":"Status"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'hireOrderGridStatus',
          'Status',
          _vm.hireStatus.value
        )}},model:{value:(_vm.hireStatus.value),callback:function ($$v) {_vm.$set(_vm.hireStatus, "value", $$v)},expression:"hireStatus.value"}}):_vm._e(),(_vm.type == 'Internal')?_c('v-select',{attrs:{"items":['Outstanding', 'Part-Invoiced', 'Complete', 'Cancelled'],"label":"Status"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'internalOrderGridStatus',
          'Status',
          _vm.internalStatus.value
        )}},model:{value:(_vm.internalStatus.value),callback:function ($$v) {_vm.$set(_vm.internalStatus, "value", $$v)},expression:"internalStatus.value"}}):_vm._e()],1),_c('order-table',{key:_vm.$route.name,attrs:{"loading":_vm.loading,"type":_vm.type,"orders":_vm.filteredItems,"total-items":_vm.totalCount},on:{"load-more":_vm.loadMoreOrders,"update:sort":_vm.handleSort}}),(_vm.isLoadingMore)?_c('div',{staticClass:"text-center"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }