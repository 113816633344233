var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Stock Allocations "),_c('v-spacer')],1),_c('v-divider'),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.orderStockAllocations,"headers":_vm.headers,"search":_vm.search},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.stockCodeDescription",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('span',{attrs:{"title":_vm.getTitle(item),"color":item.color}},[_vm._v(_vm._s(item.stockCodeDescription))])])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('span',{attrs:{"title":_vm.getTitle(item),"color":item.color}},[_vm._v(_vm._s(item.customer))])])]}},{key:"item.orderNumber",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('span',{attrs:{"title":_vm.getTitle(item),"color":item.color}},[_vm._v(_vm._s(item.orderNumber))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"item.quantityToDeliver",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.quantityToDeliver))])],1)]}},{key:"item.existingAllocation",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.existingAllocation))])],1)]}},{key:"item.allocate",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.allocate))])],1)]}},{key:"item.unallocated",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(item.unallocated))])],1)]}},{key:"item.freeStock",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('v-chip',{attrs:{"title":_vm.getTitle(item),"color":item.color,"dark":""}},[_vm._v(_vm._s(+item.freeStock.toFixed(4)))])],1)]}},{key:"item.quantityToAllocate",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-text-field',{staticClass:"d-table-cell",staticStyle:{"max-width":"70px"},attrs:{"min":0,"disabled":item.invoiced > 0 ||
                  (item.allocated === item.quantity &&
                    item.unallocated === 0) ||
                  item.quantityToUnallocate > 0 ||
                  item.freeStock === 0,"type":"number","hide-details":"","title":item.quantityToAllocate},on:{"change":function($event){return _vm.quantityChanged(item)}},model:{value:(item.quantityToAllocate),callback:function ($$v) {_vm.$set(item, "quantityToAllocate", $$v)},expression:"item.quantityToAllocate"}},[_vm._v(_vm._s(item.quantity))])],1)])]}},{key:"item.quantityToUnallocate",fn:function(ref){
                  var item = ref.item;
return [_c('v-lazy',[_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-text-field',{staticClass:"d-table-cell",staticStyle:{"max-width":"70px"},attrs:{"min":0,"disabled":item.invoiced > 0 ||
                  item.quantityToAllocate > 0 ||
                  item.allocated === 0,"type":"number","hide-details":"","title":item.quantityToUnallocate},on:{"change":function($event){return _vm.quantityChanged(item)}},model:{value:(item.quantityToUnallocate),callback:function ($$v) {_vm.$set(item, "quantityToUnallocate", $$v)},expression:"item.quantityToUnallocate"}},[_vm._v(_vm._s(item.quantity))])],1)])]}}])})],1),_c('snackbar-message',{attrs:{"snackbar":_vm.snackbar,"snackbar-text":_vm.successMesssage,"color":_vm.color},on:{"close":function($event){_vm.snackbar = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }