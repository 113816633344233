var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('multi-filter',{attrs:{"title":"Quotes","filters":_vm.activeFilters},on:{"clear-filters":_vm.clearFilters,"apply-filters":_vm.applyFilters,"remove-filter":_vm.removeFilter}},[_c('v-text-field',{attrs:{"label":"Number"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('number', 'Number', _vm.number.value)}},model:{value:(_vm.number.value),callback:function ($$v) {_vm.$set(_vm.number, "value", $$v)},expression:"number.value"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.createdDate.dateRange),"clearable":"","label":"Date","readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.createdDate.dateRange = []}}},on))]}}]),model:{value:(_vm.dateRangeMenu),callback:function ($$v) {_vm.dateRangeMenu=$$v},expression:"dateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'created',
            'Date',
            _vm.createdDate.dateRange
          )},"input":_vm.closeDatePicker},model:{value:(_vm.createdDate.dateRange),callback:function ($$v) {_vm.$set(_vm.createdDate, "dateRange", $$v)},expression:"createdDate.dateRange"}})],1),_c('v-text-field',{attrs:{"label":"Company"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'companyName',
          'Company',
          _vm.companyName.value
        )}},model:{value:(_vm.companyName.value),callback:function ($$v) {_vm.$set(_vm.companyName, "value", $$v)},expression:"companyName.value"}}),_c('v-text-field',{attrs:{"label":"Company Country"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'companyCountryName',
          'Company Country',
          _vm.companyCountryName.value
        )}},model:{value:(_vm.companyCountryName.value),callback:function ($$v) {_vm.$set(_vm.companyCountryName, "value", $$v)},expression:"companyCountryName.value"}}),_c('v-select',{attrs:{"items":['Sale', 'Hire'],"label":"Type"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel('type', 'Type', _vm.type.value)}},model:{value:(_vm.type.value),callback:function ($$v) {_vm.$set(_vm.type, "value", $$v)},expression:"type.value"}}),_c('v-text-field',{attrs:{"label":"Description"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'itemDescription',
          'Description',
          _vm.itemDescription.value
        )}},model:{value:(_vm.itemDescription.value),callback:function ($$v) {_vm.$set(_vm.itemDescription, "value", $$v)},expression:"itemDescription.value"}}),_c('v-autocomplete',{attrs:{"items":_vm.users,"item-text":"name","item-value":"name","label":"Raised By","autocomplete":"off"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'createdBy',
          'Raised By',
          _vm.createdBy.value
        )}},model:{value:(_vm.createdBy.value),callback:function ($$v) {_vm.$set(_vm.createdBy, "value", $$v)},expression:"createdBy.value"}}),_c('v-text-field',{attrs:{"label":"Account Code"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'accountCode',
          'Account Code',
          _vm.accountCode.value
        )}},model:{value:(_vm.accountCode.value),callback:function ($$v) {_vm.$set(_vm.accountCode, "value", $$v)},expression:"accountCode.value"}}),_c('v-select',{attrs:{"items":['Created', 'Sale', 'Hire', 'Issued', 'Closed', 'Void'],"label":"Status"},on:{"change":function($event){_vm.quoteStatusFilterChanged(_vm.quoteStatus.value),
          _vm.createOrUpdateCookieFilterModel(
            'quoteGridStatus',
            'Status',
            _vm.quoteStatus.value
          )}},model:{value:(_vm.quoteStatus.value),callback:function ($$v) {_vm.$set(_vm.quoteStatus, "value", $$v)},expression:"quoteStatus.value"}}),_c('v-select',{attrs:{"items":['Yes', 'No'],"label":"Customer Feedback"},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
          'hasCustomerFeedback',
          'Customer Feedback',
          _vm.quoteCustomerFeedback.value
        )}},model:{value:(_vm.quoteCustomerFeedback.value),callback:function ($$v) {_vm.$set(_vm.quoteCustomerFeedback, "value", $$v)},expression:"quoteCustomerFeedback.value"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.formatDateRange(_vm.customerFeedbackDate.dateRange),"clearable":"","label":"Feedback Date","readonly":"","prepend-icon":"mdi-calendar"},on:{"click:clear":function($event){_vm.customerFeedbackDate.dateRange = []}}},on))]}}]),model:{value:(_vm.customerFeedbackDateRangeMenu),callback:function ($$v) {_vm.customerFeedbackDateRangeMenu=$$v},expression:"customerFeedbackDateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},on:{"change":function($event){return _vm.createOrUpdateCookieFilterModel(
            'customerFeedbackDate',
            'Feedback Date',
            _vm.customerFeedbackDate.dateRange
          )},"input":_vm.closeCustomerFeedbackDatePicker},model:{value:(_vm.customerFeedbackDate.dateRange),callback:function ($$v) {_vm.$set(_vm.customerFeedbackDate, "dateRange", $$v)},expression:"customerFeedbackDate.dateRange"}})],1)],1),_c('quote-table',{attrs:{"loading":_vm.loading,"quotes":_vm.filteredItems,"total-items":_vm.totalCount},on:{"load-more":_vm.loadMoreQuotes,"update:sort":_vm.handleSort}}),(_vm.isLoadingMore)?_c('div',{staticClass:"text-center"},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }